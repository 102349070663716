.file-display {
  padding: 0.5rem;
  border: 1px solid lightgray;
  border-radius: 5px;

  .file {
    text-align: center;
    max-width: 80px;
    margin: 0.5rem;
    position: relative;
    overflow: hidden;

    a {
      color: white;
      text-decoration: none;
    }
    
    .file-remove {
      top: -5px;
      right: 5px;
      position: absolute;

      &:hover {
        color: var(--bs-danger);
      }
    }

    .file-icon {
      font-size: 200%;
    }

    .file-name {
      max-width: 100%;
      font-size: 60%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
