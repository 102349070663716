.page-layout {
  display: flex;
  position: relative;

  .nav {
    height: 80px;
    margin-bottom: 10px;
    padding: 0 3rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .dropdown {
      .dropdown-toggle {
        border: 2px solid #ff00fe;
        background-color: #000;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover,
        &:active,
        &:focus {
          background-color: #ff00fe;
        }

        &::after {
          display: none;
        }

        img {
          width: 20px;
        }
      }

      .dropdown-menu {
        background-color: #000;
        border: 1px solid #ff00fe;

        .dropdown-item {
          color: white;

          &:hover {
            background-color: #111313;
          }
        }
      }
    }

    .menu {
      line-height: 1;
      cursor: pointer;
      border: 2px solid #ff00fe;
      background-color: #000;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      z-index: 2;

      .bi {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .sidebar {
    min-width: 250px;
    max-width: 250px;
    padding: 1.5rem;
    background-color: #111313;
    transition: margin-left 0.3s;
    top: 0;
    left: 0;
    position: fixed;
    height: 100vh;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .logo {
      width: 100%;

      img {
        width: 100%;
        object-fit: contain;
      }
    }

    &.active {
      margin-left: -250px;
    }

    .nav-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0.5rem 0.8rem;
      font-weight: 500;
      font-size: 18px;
      text-decoration: none;
      color: #fff;
      text-decoration: none;
      z-index: 1;
      border-radius: 3px;

      &:hover {
        background-color: #ff00feaa;
      }
      &.active {
        font-weight: 700;
        background-color: #ff00fe;
      }

      .icon-holder {
        background-color: #000;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        .icon {
          width: 40px;
          height: 40px;
          font-size: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .content-holder {
    padding-left: 250px;
    width: 100%;

    .content {
      width: 100%;
      min-height: calc(100vh - 100px);
      position: relative;
    }
  }

  @media screen and (max-width: 990px) {
    .nav {
      padding: 0 1rem;

      .dropdown {
        .dropdown-toggle {
          width: 40px;
          height: 40px;
        }
      }

      .menu {
        width: 40px;
        height: 40px;

        font-size: 25px;

        .bi {
          width: 40px;
          height: 40px;
        }
      }
    }

    .sidebar {
      position: absolute;
    }

    .content-holder {
      padding-left: 0px;
    }

    .content {
      .menu {
        left: 1.5rem;
      }
    }
  }
}
