.transactions {
  th,
  td {
    vertical-align: middle;
    white-space: nowrap;
  }

  .text-right {
    text-align: right;
  }
}
