$label-transform: scale(0.85) translateY(-1.4rem);
$label-left: 0px;
$label-opacity: 0.7;

.app-input {
  background: none;
  position: relative;
  margin-top: 1.6rem;
  margin-bottom: 0.5rem;

  textarea {
    width: 100%;
    background: none;
    color: white;
    border: none;
    padding: 0.2rem 0.7rem;

    border-bottom: 1px solid white;
    border-radius: 0;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px #0e0e0e88 inset !important;
      // background: #0e0e0e88;
    }

    &:focus,
    &:active {
      border: none;
      box-shadow: none;
      outline: none;
      background-image: none !important;
      border-bottom: 1px solid white;
    }

    &.has-value {
      border-bottom: 1px solid var(--bs-success);
    }

    &:focus + label,
    &:active + label {
      transform: $label-transform;
      left: $label-left;
      opacity: $label-opacity;
    }
    &:hover {
      border-bottom: 1px solid #00f2ff;
    }
    &:focus {
      border-bottom: 1px solid #ff00fe;
    }
    &:focus + label {
      color: #ff00fe;
    }

    &:disabled {
      border-bottom: 1px solid #ffffffaa;
      color: #ffffffaa;
    }
  }

  label {
    user-select: none;
    pointer-events: none;
    position: absolute;
    left: 5px;
    transform: scale(1) translateY(0) translateX(0);
    opacity: 1;
    transition: all 150ms ease;

    &.has-value {
      transform: $label-transform;
      left: $label-left;
      opacity: $label-opacity;
    }
  }
}
