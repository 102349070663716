.dashboard {
  .card {
    padding: 1.5rem;
    margin: 0 0 0 1rem;
    min-width: 300px;
    border-radius: 5px;
    background-color: #111313;

    .value {
      font-size: 44px;
    }
  }
}
