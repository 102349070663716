.auth {
  position: relative;

  .background {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
  }

  .logo {
    height: 100px;
    object-fit: contain;
    margin-bottom: 1rem;
  }

  .center {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card {
    background-color: #111313;
    color: #fff;
  }

  .link {
    color: white;
    cursor: pointer;
    text-decoration: underline;
  }
}
